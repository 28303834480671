/* Just the standard bootstrap alert style which clean-modal overwrites */
.alert {
	color: #721c24;
	background-color: #f8d7da !important;
 	border-color: #f5c6cb; 
 	font-family: "Inter UI var alt" ;
 	border: 1px solid transparent !important;
}

.alert-success {
	color: #155724;
	background-color: #d4edda !important;
	border-color: #c3e6cb;
	font-family: "Inter UI var alt" ;
 	border: 1px solid transparent !important;
}

/*Otherwise our modal has an annoing 1px white border */
.clean-modal div {
	background-color: #2a2a2a;
	border: none;
}

.message {
	box-shadow: none;
}